import { HashLink } from 'react-router-hash-link';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Group4331 from '../assets/images/LogoWhite.svg';
import './Footer.scss';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className='footer footer-font'>
      <Container>
        <Row className='first-row'>
          <Col
            xs={{ order: 1, span: 10, offset: 1 }}
            lg={{ span: 5, offset: 0 }}
            xl={4}
            className='text-center text-lg-start'
          >
            <Image src={Group4331} />
            <p>
              Citiapp is on a mission to building new smart cities by putting the
              citizens at the center of the initiative.
            </p>
          </Col>
          <Col
            xs={{ order: 3, span: 12 }}
            lg={{ order: 2, span: 7 }}
            xl={8}
            className='d-flex flex-column align-items-center align-items-lg-end justify-content-end'
          >
            <Row className='text-center text-lg-end address'>
              <Col xs={4} sm={'auto'} lg={12}>
                Sundsvall, Sweden
              </Col>
              <Col xs={4} sm={'auto'} lg={12}>
                info@citiapp.se
              </Col>
              <Col xs={4} sm={'auto'} lg={12}>
                +46 60 340 40
              </Col>
            </Row>
          </Col>
          <Col xs={{ order: 2, span: 12 }} lg={{ order: 3 }}>
            <Row className='nav-row text-center'>
              <Col xs={12} lg={'auto'}>
                <HashLink to='/about#top'>{t('Header.About')}</HashLink>
              </Col>
              <Col xs={12} lg={'auto'}>
                <p className='d-none d-lg-block'>&bull;</p>
              </Col>
              <Col xs={12} lg={'auto'}>
                <HashLink to='/mobileapp#top'>
                  {t('Header.Citiapp Mobile')}
                </HashLink>
              </Col>
              <Col xs={12} lg={'auto'}>
                <p className='d-none d-lg-block'>&bull;</p>
              </Col>
              <Col xs={12} lg={'auto'}>
                <HashLink to='/dashboard#top'>
                  {t('Header.Citiapp Dashboard')}
                </HashLink>
              </Col>
              <Col xs={12} lg={'auto'}>
                <p className='d-none d-lg-block'>&bull;</p>
              </Col>
              <Col xs={12} lg={'auto'}>
                <HashLink to='/request' elementId='faq-section'>
                  {t('Header.FAQ')}
                </HashLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='bottom-block'>
              <div className='apps-block'>
                <a
                  href='https://olivux.se/'
                  target='_blank'
                  rel='noreferrer'
                  className='olivux'
                >
                  <div />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.citiapp0630'
                  target='_blank'
                  rel='noreferrer'
                  className='googleplay'
                >
                  <div />
                </a>
                <a
                  href='https://apps.apple.com/se/app/citiapp/id1639882237?l=en'
                  target='_blank'
                  rel='noreferrer'
                  className='appstore'
                >
                  <div />
                </a>
              </div>
              <div className='texts-block'>
                <p>Powered by Olivux 2022</p>
                <p className='delimiter'>|</p>
                <a
                  href='/privacy#top'
                  target='_blank'
                  rel='noreferrer'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
